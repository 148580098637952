var exports = {};

(function () {
  "use strict";

  var to_String = Object.prototype.toString;
  var hasOwn = Object.prototype.hasOwnProperty;
  var util = {};
  util.cl = console.log;

  util.isDefined = function (v) {
    return !(typeof v === "undefined" || v === null);
  };

  util.def = function (v, def) {
    return util.isDefined(v) ? v : def;
  };

  util.is = function (obj, type) {
    return to_String.call(obj) === "[object " + type + "]";
  };

  util.isObject = function (obj) {
    return util.is(obj, "Object");
  };

  util.isArray = function (arr) {
    return util.is(obj, "Array");
  };

  util.isBool = function (bool) {
    return bool === false || bool === true;
  }; // Loop object or array


  util.length = function (obj) {
    return Object.keys(obj).length;
  };

  util.size = util.length; // returns an object or an array

  util.makeIterable = function (obj) {
    return util.isObject(obj) ? obj : [].concat(obj);
  };

  util.arrayWhile = function (arr, cb) {
    var elem = arr.pop();

    while (typeof elem !== "undefined") {
      cb(elem);
      elem = arr.pop();
    }
  };

  util.arrayEach = function (arr, cb) {
    for (var i = 0; i < arr.length; i++) {
      cb(arr[i], i, arr);
    }
  };

  util.each = function (obj, cb) {
    // don't use object version of each for array, undefined values are not iterated over otherwise
    if (util.isObject(obj)) {
      util.arrayWhile(Object.keys(obj), function (key) {
        cb(obj[key], key, obj);
      });
    } else {
      obj = [].concat(obj); // make sure it is an array

      util.arrayEach(obj, cb);
    }
  };

  util.map = function (obj, cb) {
    obj = makeIterable(obj);
    var newObj = Object.create(obj.constructor.prototype);
    util.each(obj, function (v, k) {
      newObj[k] = cb(v, k, obj);
    });
    return newObj;
  };

  util.reduce = function (obj, cb, initial) {
    obj = makeIterable(obj);
    var total = initial || 0;
    util.each(obj, function (v, k) {
      total = cb(total, v, k, obj);
    });
    return total;
  };

  util.select = function (obj, cb) {
    obj = makeIterable(obj);
    var newObj = Object.create(obj.constructor.prototype);
    util.each(obj, function (v, k) {
      if (cb(v, k, obj)) {
        newObj[k] = v;
      }
    });
    return newObj;
  };

  util.filter = util.select;

  util.arrayShuffle = function (arr) {
    // http://bost.ocks.org/mike/shuffle/
    var m = arr.length,
        t,
        i; // While there remain elements to shuffle…

    while (m) {
      // Pick a remaining element
      i = Math.floor(Math.random() * m--); // And swap it with the current element.

      t = arr[m];
      arr[m] = arr[i];
      arr[i] = t;
    }

    return arr;
  };

  util.shuffle = function (obj) {
    var keys = Object.keys(obj),
        m = keys.length,
        t,
        i,
        n;

    while (m) {
      i = keys[Math.floor(Math.random() * m--)];
      n = keys[m];
      t = obj[n];
      obj[n] = obj[i];
      obj[i] = t;
    }

    return obj;
  };

  util.random = function (max, min) {
    min = min || 0;
    max = max || 10;
    return Math.round(Math.random() * (max - min)) + min;
  };

  util.randomString = function (length, chars) {
    chars = util.def(chars, "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(""));
    var str = "";

    for (var i = 0; i < length; i++) {
      str += chars[util.random(chars.length - 1, 0)];
    }

    return str;
  };

  exports = exports = util;
})();

export default exports;